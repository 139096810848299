<template>
  <select-input
    :ref="name"
    v-tooltip.bottom="'Multi-Agency projects cannot be renamed.'"
    :name="name"
    :label="label"
    :hide-label="hideLabel"
    :model-value="modelValue"
    :required="required"
    :options="options"
    option-label="name"
    option-value="value"
    :clearable="true"
    :multiple="false"
    :errors="errors"
    disabled
    @update:model-value="onProjectChanged"
  />
</template>

<script>
import searchMixin from '@/js/mixins/searchMixin.js';
import formInput from '@/js/mixins/formInput.js';
import SelectInput from '@canopyllc/roots/components/forms/SelectInput.vue';
import API, {replacePlaceholderWithId} from '@canopyllc/roots/utils/api.js';

export default {
  name: 'LinkedProjectInput',
  components: {
    SelectInput,
  },
  mixins: [formInput, searchMixin],
  inject: ['urls', 'csrfToken'],
  data() {
    return {
      api: null,
      year: 1,
    };
  },
  mounted() {
    this.api = new API(this.csrfToken);
    this.onProjectChanged(this.localValue);
  },
  methods: {
    async onProjectChanged(projectId) {
      const url = replacePlaceholderWithId(this.urls.objectDetailUrl, projectId),
            {data} = await this.api.get(url);
      this.year = data.year_of_implementation;
    },
  },
};
</script>

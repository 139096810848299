import API from '@canopyllc/roots/utils/api.js';
import BaseTaskButton from '@/js/components/BaseTaskButton.vue';
import BlankSlate from '@canopyllc/roots/components/BlankSlate.vue';
import Changes from '@/js/modules/changes.js';
import CheckboxInput from '@canopyllc/roots/components/forms/CheckboxInput.vue';
import CheckboxListInput from '@canopyllc/roots/components/forms/CheckboxListInput.vue';
import DateTimeInput from '@canopyllc/roots/components/forms/DateTimeInput.vue';
import Formset from '@/js/components/Formset.vue';
import FormsetWithSum from '@/js/components/FormsetStaffing.vue';
import LinkedProjectInput from '@/js/components/forms/LinkedProjectInput.vue';
import MandatoryGrid from '@/js/components/MandatoryGrid.vue';
import NumberFieldSum from '@/js/components/SumRow.vue';
import NumberInput from '@canopyllc/roots/components/forms/NumberInput.vue';
import RadioButtonGroupInput from '@/js/components/forms/RadioButtonGroupInput.vue';
import RadioGroupInput from '@canopyllc/roots/components/forms/RadioGroupInput.vue';
import RichTextInput from '@canopyllc/roots/components/forms/RichTextInput.vue';
import SelectInput from '@canopyllc/roots/components/forms/SelectInput.vue';
import SelectInputAjax from '@canopyllc/roots/components/forms/SelectInputAjax.vue';
import TaskButtonGeneratePdf from '@/js/components/TaskButtonGeneratePdf.vue';
import TextAreaInput from '@canopyllc/roots/components/forms/TextareaInput.vue';
import TextareaWithHeadingInput from '@/js/components/forms/TextareaWithHeadingInput.vue';
import TextInput from '@canopyllc/roots/components/forms/TextInput.vue';
import LegacyFileInput from '@/js/components/forms/LegacyFileInput.vue';
import TotalBudgetReview from '@/js/apps/reviews/TotalBudgetReview.vue';
import UploadFileInput from '@canopyllc/roots/components/forms/UploadFileInput.vue';
import UploadImportFileInput from '@/js/components/forms/UploadImportFileInput.vue';
import VTooltip from '@/js/directives/v-tooltip.js';
import {createApp, nextTick} from 'vue';
import {focusFirstTextInput} from '@canopyllc/roots/utils/domUtil.js';
import TaskButtonRunCommand from '@/js/components/TaskButtonRunCommand.vue';
import ConfirmSave from '@/js/modules/confirmSave.js';
import DoubleClickDisable from '@/js/modules/doubleClickDisable.js';
import DisplayText from '@/js/components/forms/DisplayText.vue';
import NumberedCheckboxListInput from '@/js/components/forms/NumberedCheckboxListInput.vue';

/**
 * Setup Hybrid forms
 *
 * Use this function to render the vue components in django forms
 *
 * @param {String} csrfToken
 * @param {Object} urls
 * @param {String} target
 * @param {Boolean} ignoreChanges
 * @param {Object} conditional
 * @param {Boolean} autofocus
 * @param {Boolean} confirmSave - should we post the form to the confirm save handler on the current page before actually saving?
 * @param {Boolean} includesHack - this is for run time ad hawk js to run after the form is loaded USE WITH CAUTION!!!!
 * @constructor
 */
window.SetupForms = function({
  csrfToken, urls, target = '#vue-form', ignoreChanges = false, conditional = null, autofocus = true, confirmSave = false, includesHack = false,
}) {
  const app = createApp({}),
        globalComponents = [
          BaseTaskButton,
          BlankSlate,
          CheckboxInput,
          CheckboxListInput,
          DateTimeInput,
          DisplayText,
          Formset,
          FormsetWithSum,
          LegacyFileInput,
          LinkedProjectInput,
          MandatoryGrid,
          NumberedCheckboxListInput,
          NumberFieldSum,
          NumberInput,
          RadioButtonGroupInput,
          RadioGroupInput,
          RichTextInput,
          SelectInput,
          SelectInputAjax,
          TaskButtonGeneratePdf,
          TaskButtonRunCommand,
          TextAreaInput,
          TextareaWithHeadingInput,
          TextInput,
          TotalBudgetReview,
          UploadFileInput,
          UploadImportFileInput,
        ],
        api = new API(csrfToken);

  // Register global components
  globalComponents.forEach((component) => {
    app.component(component.name, component);
  });

  // Register global provides
  app.provide('csrfToken', csrfToken);
  app.provide('conditional', conditional);
  app.provide('urls', urls);
  app.provide('api', api);
  app.provide('useIconFont', false);
  app.directive('tooltip', VTooltip);

  app.mount(target);

  /**
   * We have to use next tick because we have to wait for the vue components to be mounted
   * setting up the changes listener before next tick won't pick up vue components
   * Mounted function on the root instance doesn't seem to be called after all the children have mounted,
   * which is odd.
   */
  nextTick(() => {
    if (ignoreChanges === false) {
      Changes.setup(target);
    }

    if (confirmSave === true) {
      ConfirmSave.setup({target, api});
    }

    // don't allow double clicks on save buttons
    // important it must go last in case Changes or ConfirmSave cancel a form submission
    // and last registered fires first
    DoubleClickDisable.setup(target);

    if (includesHack) {
      window.executeHack();
    }

    if (autofocus === true) {
      focusFirstTextInput();
    }
  });
};
